<template>
    <section>
        <div class="row mx-0 bg-white mt-2 py-3 br-12">
            <div class="col text-center border-right">
                <p class="text-general f-30 f-500">{{ separadorNumero(deudaGeneral.total_comisiones) }}</p>
                <p class="text-general f-14 mt-1">Comisiones sin pagar</p>
            </div>
            <div class="col text-center border-right">
                <p class="text-general f-30 f-500">{{ deudaGeneral.total_pedidos }}</p>
                <p class="text-general f-14 mt-1">Pedidos</p>
            </div>
            <div class="col text-center border-right">
                <p class="text-general f-30 f-500">{{ deudaGeneral.total_lecheros }}</p>
                <p class="text-general f-14 mt-1">{{ $config.vendedor + 's' }}</p>
            </div>
            <div class="col text-center">
                <p class="text-general f-30 f-500">{{ deudaGeneral.total_cedis }}</p>
                <p class="text-general f-14 mt-1">CEDIS</p>
            </div>
        </div>
        <div class="row mx-0 mt-3">
            <div class="col-12 px-0">
                <tabla-general :data="dataTable" :columnas="dataColumns" alto="calc(100vh - 407px)" class-header="text-general f-16">
                    <template slot="cabecera-izquierda">
                        <div class="col-auto text-general f-18 f-600">
                            Pedidos pendientes por gestionar la comisión
                        </div>
                    </template>
                    <template slot="adicionales-derecha">
                        <el-table-column label="Valor Comisión" align="center" min-width="150">
                            <template slot-scope="{row}">
                                <p class="text-general text-center">{{ convertMoney(row.total_comision,row.idm_moneda) }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column width="100">
                            <template slot-scope="{row}">
                                <div class="row mx-0">
                                    <div class="bg-general3 text-white cr-pointer f-14 br-20 px-2" @click="gestionarComision(row.id)">
                                        Liquidar
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
        <!-- Partials -->
        <modal-liquidar-comision ref="modalLiquidar" @update="refrestTabla" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import Liquidacion from '~/services/comisiones/liquidar'
export default {
    components: {
        modalLiquidarComision : () => import('./partials/modalLiquidarComision')
    },
    data(){
        return {
            dataTable: [],
            dataColumns: [
                { valor: 'lechero', titulo: 'Leechero', class: 'text-general', ancho:150 },
                { valor: 'cedis_nombre', titulo: 'Cedis', class: 'text-general', ancho:150 },
                { valor: 'total_pedidos', titulo: 'Núm. de pedidos', class: 'text-general text-center', ancho:100 },
            ],
            deudaGeneral:{}
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis'
        }),
    },
    watch:{
        id_cedis(val){
            this.getTabla()
            this.getInfoGeneral()
        }
    },
    mounted(){
        if(this.id_cedis != null){
            this.getTabla()
            this.getInfoGeneral()
        }
        this.dataColumns[0].titulo = this.$config.vendedor
    },
    methods: {
        async getTabla(){
            try {

                const {data} = await Liquidacion.listaPorPagar()
                this.dataTable = data.lecheros
            } catch (e){
                this.error_catch(e)
            }
        },
        async getInfoGeneral(){
            try {
                const {data} = await Liquidacion.generalPorPagar()
                this.deudaGeneral = data
            } catch (e){
                this.error_catch(e)
            }
        },
        refrestTabla(){
            // let index = this.dataTable.findIndex(o=>o.id === id)
            // this.dataTable.splice( index, 1 );
            this.getTabla()
            this.getInfoGeneral()
        },
        gestionarComision(id_tienda){
            this.$refs.modalLiquidar.toggle(id_tienda);
        }
    }
}
</script>
